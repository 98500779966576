import { useEffect, useState } from 'react'
import {
  Form,
  useLoaderData,
  useSubmit,
  type LoaderFunctionArgs,
  useSearchParams,
  type ActionFunctionArgs,
  useActionData,
  Outlet,
  useOutletContext,
  useLocation,
  Link,
} from 'react-router-dom'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Search, useGlobalState } from '@trinity/components'
import {
  getFabricOrders,
  parseFormData,
  parseSearchParams,
  type UseActionData,
  type UseLoaderData,
  FabricOrderStatus,
} from '@trinity/utils'
import { ADD_TO_SHIPMENT, PRINT_LABEL, handleFabricOrderAction } from 'utils'

const FULFILLED = 'fulfilled'
const OFF = 'off'
const ALL = 'all'

export async function loader({ request }: LoaderFunctionArgs) {
  const params = parseSearchParams(request)
  const url = new URL(request.url)
  const isBoltOrder = url.pathname.includes('bolt')
  const isLabelPage = url.pathname.includes('label')
  const boltOrder = isBoltOrder ? 1 : 0
  const isFulfilledStatus = params.status === FULFILLED
  const { fabricOrders, totalFabricOrders } = await getFabricOrders({ ...params, boltOrder })

  return { fabricOrders, totalFabricOrders, isBoltOrder, isLabelPage, isFulfilledStatus }
}

export async function action({ request }: ActionFunctionArgs) {
  const params = await parseFormData(request)

  return await handleFabricOrderAction(params)
}

interface SelectedFabricOrderType {
  selectedFabricOrders: TrinityAPI.FabricOrderType[]
  setSelectedFabricOrders: React.Dispatch<React.SetStateAction<TrinityAPI.FabricOrderType[]>>
}

export function useFabricOrderData() {
  return useOutletContext<SelectedFabricOrderType>()
}

export function FabricOrders() {
  const { setToastInfo, onMobile } = useGlobalState()
  const [selectedFabricOrders, setSelectedFabricOrders] = useState<TrinityAPI.FabricOrderType[]>([])
  const { isBoltOrder, isLabelPage } = useLoaderData() as UseLoaderData<typeof loader>
  const actionData = useActionData() as UseActionData<typeof action>
  const title = isLabelPage ? 'Fabric Order Label(s)' : isBoltOrder ? 'Bolt Fabric Orders' : 'Single Cut Fabric Orders'

  useEffect(() => {
    if (actionData?.message) {
      setToastInfo({
        show: true,
        message: String(actionData.message),
        severity: actionData.error ? 'error' : 'success',
      })
    }
  }, [actionData, setToastInfo])

  return (
    <Stack spacing={4} pt={{ mobile: 10, laptop: 0 }} alignItems={isLabelPage ? 'center' : 'normal'}>
      <Typography variant='h4'>{title}</Typography>
      {!isLabelPage && <SearchAndFilter />}
      {!onMobile && !isLabelPage && (
        <ActionButtons selectedFabricOrders={selectedFabricOrders} setSelectedFabricOrders={setSelectedFabricOrders} />
      )}
      <Outlet context={{ selectedFabricOrders, setSelectedFabricOrders } satisfies SelectedFabricOrderType} />
    </Stack>
  )
}

function SearchAndFilter() {
  const [params] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const status = params.get('status') ?? FabricOrderStatus.PENDING
  const factory = params.get('factory') ?? ALL
  const location = useLocation()

  return (
    <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={{ mobile: 2, laptop: 4 }}>
      <Box width={{ laptop: 1 / 3 }}>
        <Search
          method='get'
          name='supplierFabricNumber'
          placeholder='Filter by supplier fabric number'
          initialValue={params.get('q') ?? ''}
        />
      </Box>
      <Button
        variant='outlined'
        onClick={e => setAnchorEl(e.currentTarget)}
        startIcon={<ExpandMore fontSize='large' />}
        sx={{ textTransform: 'capitalize', textWrap: 'nowrap', borderRadius: 50 }}
      >
        {`${status} / ${factory} Orders`}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: 65,
        }}
      >
        <Form method='get' action={location.pathname}>
          <Stack spacing={4} p={4}>
            <Stack direction={{ mobile: 'row', desktop: 'column' }} spacing={{ mobile: 12, laptop: 4 }}>
              <FormControl size='small'>
                <FormLabel id='factory-radio-buttons-group-label' sx={{ typography: 'h6' }}>
                  Factory
                </FormLabel>
                <RadioGroup
                  key={`radio-${params.get('factory')}`}
                  aria-labelledby='factory-radio-buttons-group-label'
                  name='factory'
                  defaultValue={params.get('factory') ?? ALL}
                >
                  <FormControlLabel value={ALL} control={<Radio />} label='All' />
                  <FormControlLabel value='T2ID' control={<Radio />} label='T2iD' />
                  <FormControlLabel value='ID' control={<Radio />} label='iD' />
                </RadioGroup>
              </FormControl>
              <FormControl size='small'>
                <FormLabel id='status-radio-buttons-group-label' sx={{ typography: 'h6' }}>
                  Status
                </FormLabel>
                <RadioGroup
                  key={`radio-${params.get('status')}`}
                  aria-labelledby='status-radio-buttons-group-label'
                  name='status'
                  defaultValue={params.get('status') ?? FabricOrderStatus.PENDING}
                >
                  <FormControlLabel value={FabricOrderStatus.PENDING} control={<Radio />} label='Pending' />
                  <FormControlLabel value={OFF} control={<Radio />} label='Failures (OFF)' />
                  <FormControlLabel value={FULFILLED} control={<Radio />} label='Fulfilled' />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Button type='submit' size='small' onClick={() => setAnchorEl(null)} sx={{ width: 1, mb: 2, mr: 2 }}>
              Apply
            </Button>
          </Stack>
        </Form>
      </Popover>
    </Stack>
  )
}

function ActionButtons({ selectedFabricOrders, setSelectedFabricOrders }: SelectedFabricOrderType) {
  const { fabricOrders, isFulfilledStatus, isBoltOrder } = useLoaderData() as UseLoaderData<typeof loader>
  const submit = useSubmit()
  const location = useLocation()
  const id = selectedFabricOrders.map(fabricOrder => fabricOrder.id)

  return (
    <Stack direction='row' justifyContent='space-between' pt={4}>
      <Stack direction='row' spacing={2}>
        <Button
          variant='outlined'
          color='neutral'
          size='small'
          sx={{ borderRadius: 50 }}
          onClick={() => setSelectedFabricOrders(fabricOrders)}
        >
          Select All
        </Button>
        <Button
          variant='outlined'
          color='error'
          size='small'
          sx={{ borderRadius: 50 }}
          onClick={() => setSelectedFabricOrders([])}
        >
          Clear Selection
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            setSelectedFabricOrders([])
            submit({ action: PRINT_LABEL, id }, { method: 'post', action: location.pathname })
          }}
        >
          Print Label
        </Button>
        <Button
          variant='outlined'
          size='small'
          disabled={isFulfilledStatus}
          onClick={() => {
            setSelectedFabricOrders([])
            submit({ action: ADD_TO_SHIPMENT, id }, { method: 'post' })
          }}
        >
          Add To Shipment
        </Button>
        <div>
          <Button
            component={Link}
            to={`/resources/fabric-orders.csv${location.search}&boltOrder=${isBoltOrder ? 1 : 0}`}
            disabled={!fabricOrders.length}
            size='small'
            sx={{ ml: 10 }}
          >
            Export To CSV
          </Button>
        </div>
      </Stack>
    </Stack>
  )
}
